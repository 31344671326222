<template>
  <div class="product-page">
    <redirect class="mb-5" title="Menu Filter" route="website.menufilter" />
    <v-row no-gutters>
      <v-col sm="12" class="page-header">
        <span class="page-title">{{ $t('Update Filter') }}</span>
      </v-col>
    </v-row>
    <!-- là bảng create Variant size color -->
    <div class="elevation-1 px-3">
      <v-row no-gutters class="mt-0">
        <v-col sm="2" class="">
          <v-radio-group v-model="filterType">
            <v-radio
              v-if="filterType == 'variants'"
              off-icon="mdi-checkbox-blank-outline"
              on-icon="mdi-checkbox-marked"
              color="primary"
              value="variants"
              label="Variant"
              @change="turnOffCreate()"
            >
            </v-radio>
            <transition name="slide-fade">
              <v-radio-group class="pl-8" v-if="filterType == 'variants'" v-model="variantFilterType">
                <v-radio
                  color="primary"
                  v-if="variantFilterType == 'Color' || variantFilterType == 'color'"
                  @change="turnOffCreate()"
                  value="Color"
                  label="Color"
                ></v-radio>
                <v-radio
                  color="primary"
                  v-if="variantFilterType == 'Size' || variantFilterType == 'size'"
                  @change="turnOffCreate()"
                  value="Size"
                  label="Size"
                ></v-radio>
              </v-radio-group>
            </transition>
            <v-radio
              v-if="filterType == 'options'"
              off-icon="mdi-checkbox-blank-outline"
              on-icon="mdi-checkbox-marked"
              color="primary"
              value="options"
              label="Option"
              @change="turnOffCreate()"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <!-- là bảng create Option -->
      <div v-if="filterType == 'options' && turnOnCreate">
        <v-row class="" v-for="(item, ind) in listOption" :key="ind">
          <v-col align-self="center" sm="2">Option Name:</v-col>
          <v-col align-self="center" sm="3"
            ><v-text-field
              placeholder="Longer than 3 characters"
              hide-details=""
              class="mb-0"
              v-model="item.optionName"
            ></v-text-field
          ></v-col>
          <v-col align-self="center" sm="1">Value:</v-col>
          <v-col align-self="start" sm="5">
            <v-combobox
              @change="clearSpace(item)"
              v-model.trim="item.value"
              hide-selected
              multiple
              persistent-hint
              small-chips
              placeholder="Required"
              hide-details
              append-icon=""
              :delimiters="[',']"
            >
              <template v-slot:selection="data">
                <v-chip
                  close
                  small
                  label
                  :key="data.item"
                  :disabled="data.disabled"
                  @click:close="remove(data.item, ind)"
                >
                  {{ data.item }}
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
          <!-- <v-col align-self="center" sm="1"
            ><v-btn class="primary"><v-icon>mdi mdi-delete mdi-18px</v-icon></v-btn></v-col
          > -->
        </v-row>
      </div>
      <!-- là cái bảng value ở dưới -->
      <!-- value color -->
      <v-row
        no-gutters
        class="mt-5 mx-0"
        v-if="
          filterType == 'variants' && (variantFilterType == 'Color' || variantFilterType == 'color') && turnOnCreate
        "
      >
        <v-col sm="12"><h3>Value</h3></v-col>
        <v-divider></v-divider>
      </v-row>
      <transition name="slide-value">
        <div
          v-if="
            filterType == 'variants' && (variantFilterType == 'Color' || variantFilterType == 'color') && turnOnCreate
          "
        >
          <transition-group name="list-value">
            <v-row class="d-flex" v-for="(item, ind1) in dataColor" :key="ind1 + '1'">
              <v-col sm="1"> {{ ind1 + 1 }} </v-col>
              <v-col sm="1"
                ><v-icon
                  v-if="item.value != 'Colorful'"
                  :class="item.value == 'White' ? 'border' : ''"
                  :color="item.code"
                  >mdi-checkbox-blank-circle </v-icon
                ><v-img
                  v-else
                  class="colorful"
                  src="https://minio.lattehub.com/img/200/200/resize/610e57f8d7768100099c00e5/2021/08/13/lattehub-image-6115ec57e9ff659c2b811362.jpg"
                ></v-img
              ></v-col>
              <v-col sm="2">{{ utilities.stringLimit(item.value, 20) }}</v-col>
              <v-col sm="1" class="text-center btn" @click="deleteColorData(ind1)"
                ><v-btn class="primary"><v-icon>mdi mdi-delete mdi-18px</v-icon></v-btn></v-col
              >
            </v-row>
          </transition-group>
          <v-btn class="mb-5" color="primary" @click="openEditColor()">Add Value</v-btn>
        </div>
      </transition>
      <!-- value size -->
      <v-row
        no-gutters
        class="mt-5 mx-0"
        v-if="filterType == 'variants' && (variantFilterType == 'Size' || variantFilterType == 'size') && turnOnCreate"
      >
        <v-col sm="12"><h3>Value</h3></v-col>
        <v-divider></v-divider>
      </v-row>
      <transition name="slide-value">
        <div
          v-if="
            filterType == 'variants' && (variantFilterType == 'Size' || variantFilterType == 'size') && turnOnCreate
          "
        >
          <transition-group name="list-value">
            <!--transition group không nhận key == index -->
            <v-row v-for="(item, ind2) in dataSize" :key="ind2 + '1'">
              <v-col sm="1"> {{ ind2 + 1 }} </v-col>
              <!-- :style="`border: 1px solid ${item.color}`" -->
              <v-col sm="1"
                ><strong>{{ utilities.stringLimit(item, 20) }}</strong></v-col
              >
              <v-col sm="2" class="text-center btn" @click="deleteSizeData(ind2)"
                ><v-btn class="primary"><v-icon>mdi mdi-delete mdi-18px</v-icon></v-btn></v-col
              >
            </v-row>
          </transition-group>
          <v-btn class="mb-5" color="primary" @click="openEditSize()">Add Value</v-btn>
        </div>
      </transition>
    </div>
    <!-- Xuất hiện khi click btn Edit ở Variant Color -->
    <v-dialog width="550" v-model="turnOnEditColor">
      <v-card class="px-5 py-5">
        <v-card-title class="pl-0 pt-0">
          Add Value
        </v-card-title>
        <div class="close-icon" @click="turnOnEditColor = false">
          <v-icon>fas fa-times</v-icon>
        </div>
        <div>Color Name</div>
        <v-text-field v-model.trim="colorName"></v-text-field>
        <div>HEX</div>
        <!-- {{ colors.hex }} -->
        <v-text-field :background-color="colors.hex" v-model="colors.hex" readonly></v-text-field>
        <v-card-subtitle>
          <div class="sketch-color">
            <sketch-picker style="margin:auto" @input="updateValue" v-model="colors" />
          </div>
        </v-card-subtitle>
        <v-card-actions
          ><v-spacer></v-spacer>
          <v-btn color="primary" @click="addColorData(colorName, colors.hex)">Save</v-btn></v-card-actions
        >
      </v-card>
      <!-- <v-card realtive class="px-5 py-5">
        <v-card-title class="pl-0 pt-0">
          Add Value
        </v-card-title>
        <div class="close-icon" @click="turnOnEditColor = false">
          <v-icon>fas fa-times</v-icon>
        </div>
        <v-row no-gutters>
          <v-col sm="8">
            <v-combobox
              dense
              v-model="modelDataColorEdit"
              :items="dataColorEdit"
              chips
              placeholder="Click here"
              multiple
              :delimiters="[',']"
              @change="checkEmptyColor()"
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click:close="removeColor(data.item, modelDataColorEdit)"
                >
                  {{ utilities.stringLimit(data.item, 10) }}
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="addColorData()">Save</v-btn>
        </v-card-actions>
      </v-card> -->
    </v-dialog>
    <!-- Xuất hiện khi click btn Edit ở Variant Size -->
    <v-dialog width="550" v-model="turnOnEditSize">
      <v-card class="px-5 py-5">
        <v-card-title class="pl-0 pt-0">Add Value</v-card-title>
        <div class="close-icon" @click="turnOnEditSize = false">
          <v-icon>fas fa-times</v-icon>
        </div>
        <v-row no-gutters>
          <v-col sm="8">
            <v-combobox
              dense
              v-model="modelDataSizeEdit"
              :items="dataSizeEdit"
              chips
              placeholder="Click here"
              multiple
              :delimiters="[',']"
              @change="checkEmptySize()"
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click:close="removeColor(data.item, modelDataSizeEdit)"
                >
                  {{ utilities.stringLimit(data.item, 10) }}
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="addSizeData()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- nút Create ở dưới -->
    <v-row class="justify-end mt-1">
      <v-col class="text-right" sm="2">
        <v-btn color="primary" :disabled="checkDisable" v-if="changeToSave" @click="onSave()">Save</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { menuFilterApi } from '@/apis/menufilter';
import utilities from '@/helpers/utilities';
export default {
  data() {
    return {
      colors: {},
      colorName: '',
      defaultData: {},
      utilities,
      position: 0, // để thay đổi giữa Save và Create
      turnOnEditSize: false,
      turnOnEditColor: false,
      modelDataSizeEdit: '',
      modelDataColorEdit: '',
      dataColor: [],
      dataSize: [],
      id: '',
      listAllSize1: ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
      listAllColor1: [
        'Brown',
        'Yellow',
        'White',
        'Black',
        'Blue',
        'Grey',
        'Violet',
        'Pink',
        'Red',
        'Beige',
        'Green',
        'Orange',
        'Colorful',
      ],
      changeToSave: true,
      turnOnCreate: true,
      filterType: '',
      variantFilterType: '',
      // là mảng filter menu ở màn index
      listFilter: {
        title: '',
        avail: '',
        value: '',
      },
      // là mảng ở filter Option
      listOption: [
        {
          optionName: '',
          value: [],
        },
      ],
      isLoading: false,
    };
  },
  computed: {
    checkDisable() {
      let result = true;
      if (this.filterType == 'options') {
        let a = 0;
        for (let index = 0; index < this.listOption.length; index++) {
          const element = this.listOption[index];
          if (element.optionName.length > 2 && element.value.length > 0) {
            a += 1;
          }
        }
        if (a == this.listOption.length) {
          result = false;
        }
      }
      if (this.filterType == 'variants' && (this.variantFilterType == 'Color' || this.variantFilterType == 'color')) {
        if (this.dataColor.length > 0) {
          result = false;
        }
      }
      if (this.filterType == 'variants' && (this.variantFilterType == 'Size' || this.variantFilterType == 'size')) {
        if (this.dataSize.length > 0) {
          result = false;
        }
      }
      return result;
    },
    // dataColorEdit() {
    //   clone lại mảng đủ
    //   let a = this.listAllColor1.map(x => x);
    //   let b = [];
    //   lọc ra những item đã display ở Value
    //   let c = a.filter(y => !this.dataColor.includes(y));
    //   c.forEach(i => {
    //     b.push(i);
    //   });
    //   return b;
    // },
    dataSizeEdit() {
      // clone lại mảng đủ
      let a = this.listAllSize1.map(x => x);
      let b = [];
      // lọc ra những item đã display ở Value
      let c = a.filter(y => !this.dataSize.includes(y));
      c.forEach(i => {
        b.push(i);
      });
      return b;
    },
  },
  async created() {
    await this.getData();
  },
  methods: {
    updateValue() {
      this.defaultData.fontColor = this.colors.hex;
    },
    // convertColor(item) {
    //   let result;
    //   this.listAllColor.filter(i => {
    //     if (i.title == item) {
    //       result = i.color;
    //     }
    //   });
    //   return result;
    // },
    removeColor(item, model) {
      let index = model.indexOf(item);
      console.log(index);
      if (index > -1) {
        model.splice(index, 1);
      }
    },
    // checkEmptyColor() {
    //   this.colorName = this.colorName.filter(i => i.title.trim() != '');
    //   let dataColorUpperCase = this.dataColor.map(i => i.title.toUpperCase());
    //   this.colorName = this.colorName.filter(i => !dataColorUpperCase.includes(i.toUpperCase()));
    // },
    checkEmptySize() {
      this.modelDataSizeEdit = this.modelDataSizeEdit.filter(i => i.trim() != '');
      let dataSizeUpperCase = this.dataSize.map(i => i.toUpperCase());
      this.modelDataSizeEdit = this.modelDataSizeEdit.filter(i => !dataSizeUpperCase.includes(i.toUpperCase()));
    },
    clearSpace(item) {
      item.value = item.value.filter(i => i.trim() != '');
    },
    remove(item, ind) {
      const index = this.listOption[ind].value.indexOf(item);
      if (index >= 0) {
        this.listOption[ind].value.splice(index, 1);
      }
    },
    addSizeData() {
      this.modelDataSizeEdit.forEach(i => {
        this.dataSize.push(i);
      });
      // this.dataSize = [...this.dataSize, ...this.modelDataSizeEdit];
      this.modelDataSizeEdit = '';
      this.turnOnEditSize = false;
      this.changeToSave = true;
    },
    addColorData(colorName1, colorCode) {
      let a = {
        code: colorCode,
        value: colorName1,
      };
      this.colorName = '';
      this.colors.hex = '';
      // để loại bỏ các màu có tên trùng nhau
      let count = 0;
      for (let index = 0; index < this.dataColor.length; index++) {
        const element = this.dataColor[index];
        if (element.value.toUpperCase() == colorName1.toUpperCase()) {
          count += 1;
        }
      }
      if (count == 0) {
        this.dataColor.push(a);
      }
      this.colorName = '';
      this.colors.hex = '';
      this.turnOnEditColor = false;
      this.changeToSave = true;
    },
    openEditColor() {
      this.turnOnEditColor = true;
    },
    openEditSize() {
      this.turnOnEditSize = true;
    },
    deleteColorData(colors) {
      // this.dataColorEdit.push(this.dataColor[colors]);
      this.dataColor.splice(colors, 1);
    },
    deleteSizeData(size) {
      this.dataSizeEdit.push(this.dataSize[size]);
      this.dataSize.splice(size, 1);
    },
    async getData() {
      this.isLoading = true;
      try {
        let res = await menuFilterApi.getFilterById(this.$route.params.id);
        if (res.data.name == 'Color' || res.data.name == 'color') {
          this.turnOnCreate = true;
          this.dataColor = res.data.values;
          console.log(this.dataColor);
        }
        if (res.data.name == 'Size' || res.data.name == 'size') {
          this.turnOnCreate = true;
          let arr;
          arr = res.data.values;
          arr.forEach(i => this.dataSize.push(i.value));
          console.log(this.dataSize);
        } else {
          console.log(res.data);
          let arr = [];
          res.data.values.forEach(i => {
            arr.push(i.value);
          });
          this.turnOnCreate = true;
          this.listOption[0].optionName = res.data.name;
          this.listOption[0].value = arr;
        }
        this.filterType = res.data.type;
        this.variantFilterType = res.data.name;
        this.isLoading = false;
        this.id = res.data._id;
        this.position = res.data?.position ? res.data.position : 0;
        console.log(res.data);
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    async onSave() {
      // this.changeToSave = false;
      try {
        let params = {
          name: '',
          type: '',
          values: '',
          position: this.position,
        };
        if (this.filterType == 'variants') {
          params.name = this.variantFilterType;
          params.type = 'variants';
          if (this.variantFilterType == 'Color' || this.variantFilterType == 'color') {
            let obj = Object.assign(this.dataColor, {});
            // this.dataColor.filter(i => {
            //   arr.push(i);
            // });
            params.values = obj;
          }
          if (this.variantFilterType == 'Size' || this.variantFilterType == 'size') {
            let arr = [];
            this.dataSize.forEach(i => {
              arr.push({ value: i });
            });
            params.values = arr;
          }
        }
        if (this.filterType == 'options') {
          params.name = this.listOption[0].optionName;
          params.type = 'options';
          let arr = [];
          if (this.listOption[0].value != '') {
            this.listOption[0].value.forEach(i => {
              arr.push({ value: i });
            });
            params.values = arr;
          }
        }
        await menuFilterApi.putFilter(params, this.id);
        this.$router.push({ name: 'website.menufilter' });
      } catch (error) {
        console.log(error);
      }
    },
    onCreate() {
      // để khi update mà muốn chuyển từ variant -> options hoăc ngược lại, thì dữ liệu sẽ như lúc create
      if (this.filterType == 'variants' && (this.variantFilterType == 'Color' || this.variantFilterType == 'color')) {
        let a = [];
        this.listAllColor1.forEach(i => a.push(i));
        this.dataColor = a;
      }
      if (this.filterType == 'variants' && (this.variantFilterType == 'Size' || this.variantFilterType == 'size')) {
        this.dataSize = this.listAllSize1;
        let a = [];
        this.listAllSize1.forEach(i => a.push(i));
        this.dataSize = a;
      }
      this.turnOnCreate = true;
      this.changeToSave = true;
    },
    turnOffCreate() {
      this.turnOnCreate = true;
    },
    addOptionName() {
      this.listOption.push({
        optionName: '',
        value: '',
      });
    },
  },
};
</script>

<style>
.border {
  border: 1px solid #e6e6e6;
  border-radius: 100%;
}
.list-value-enter-active,
.list-value-leave-active {
  transition: all 0.3s;
}
.list-value-enter, .list-value-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(-30px);
}
.slide-key-enter-active,
.slide-key-leave-active {
  transition: all 0.8s ease;
}

.slide-key-enter,
.slide-key-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

.slide-value-enter-active,
.slide-value-leave-active {
  transition: all 1s ease;
}

.slide-value-enter,
.slide-value-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.close-icon {
  position: absolute;
  top: 16px;
  right: 10px;
  cursor: pointer;
}
.colorful {
  border-radius: 100%;
  width: 20px;
  height: 20px;
  margin-left: 1px;
}
</style>
